import { AxiosError, AxiosInstance } from 'axios';
import { ThirdPartyCaseTypesSchema } from 'types/index';
import { LegacyPaginationSchema } from 'types/pagination';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { captureMessage } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const ThirdPartyCasesParamsSchema = z.object({
  account_manager_id: z.string().optional(),
  case_type: z.string().optional(),
  debtor_name: z.string().optional(),
  debtor_id: z.string().optional(),
  filenumber: z.string().optional(),
  invoice_reference: z.string().optional(),
  page_limit: z.coerce.number().optional(),
  page: z.coerce.number().optional(),
  remaining_balance_operator: z.string().optional(),
  remaining_balance: z.string().optional(),
  sent_date_after: z.string().optional(),
  sent_date_before: z.string().optional(),
  sort_by: z.string().optional(),
  sort_order: z.string().optional(),
  status: z.string().optional(),
  total_tvac_operator: z.string().optional(),
  total_tvac: z.string().optional(),
  unread_messages: z.boolean().optional(),
  update_date_after: z.string().optional(),
  update_date_before: z.string().optional(),
});

const ThirdPartyCaseMessageSchema = z
  .object({
    id: z.string(),
    attributes: z.object({
      is_system: z.boolean(),
      body: z.string(),
      author: z.string().nullable(),
      owner: z.boolean(),
      attachment_url: z.string().nullable(),
      attachment_name: z.string().nullable(),
      date: z.string(),
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }));

export const ThirdPartyCaseConversationSchema = z
  .object({
    id: z.string(),
    attributes: z.object({
      messages: z.array(ThirdPartyCaseMessageSchema),
      unread: z.boolean(),
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }));

const LightThirdPartyCaseSchema = z
  .object({
    id: z.coerce.number(),
    attributes: z.object({
      case_id: z.string(),
      case_type: ThirdPartyCaseTypesSchema,
      closed: z.boolean(),
      conversation: ThirdPartyCaseConversationSchema.nullable(),
      created_at: z.string(),
      invoice_debtor_name: z.string(),
      invoice_id: z.number(),
      invoice_reference: z.string(),
      last_update: z.string(),
      started: z.boolean(),
      step_collection: z.number(),
      total_remaining: z.string(),
      total_tvac: z.string(),
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }));

const ThirdPartyCasesSchema = z.object({
  data: z.array(LightThirdPartyCaseSchema),
  metadata: z.object({
    total: z.number(),
    pagination: LegacyPaginationSchema,
  }),
});

export type ThirdPartyCases = z.infer<typeof ThirdPartyCasesSchema>;
export type LightThirdPartyCase = z.infer<typeof LightThirdPartyCaseSchema>;
export type ThirdPartyCasesFilters = z.infer<typeof ThirdPartyCasesParamsSchema>;
export type ThirdPartyCaseConversation = z.infer<typeof ThirdPartyCaseConversationSchema>;

export async function loadThirdPartyCasesQueryFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/thirdparty_cases', { params: queryKey[1] });

  const safeParse = ThirdPartyCasesSchema.safeParse(data);

  if (!safeParse.success) {
    captureMessage(
      'Zod error while parsing third party cases response. Zod error attached to sentry event.',
      { extra: { zodError: safeParse.error } },
    );

    notifications.show({
      message: 'An error has occured. Our team has been notified.',
      title: 'Error',
      color: 'red',
    });

    throw safeParse.error;
  }

  return safeParse.data;
}

export function useLoadThirdPartyCases(params?: ThirdPartyCasesFilters) {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<ThirdPartyCases, ApiError>({
    queryKey: ['third-party-cases', params],
    queryFn: (context) => loadThirdPartyCasesQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<ThirdPartyCases, AxiosError, 'thirdPartyCases'>(
    'thirdPartyCases',
    queryResult,
  );
}
